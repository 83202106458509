function getValue(key) {
    return getValues()?.[key];
}

function getValues() {
    return localStorage.getItem("m") ? JSON.parse(localStorage.getItem("m")) : {};
}

function setValues(m) {
    localStorage.setItem('m', JSON.stringify(m));
}

function setValue(key, value) {
    let m = getValues();
    m[key] = value;
    localStorage.setItem('m', JSON.stringify(m));
}



export default { getValue, getValues, setValue, setValues };