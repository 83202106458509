import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import {
  DashboardContainer,
  Sidebar,
  Logo,
  Menu,
  MenuItem,
  MenuLink,
  MainContent,
  Header,
  Title,
  UserInfo,
  UserName,
  Button,
  Card,
  Table,
  Th,
  Td
} from '../DashboardStyles';

const VaultAddress = styled.div`
  background: linear-gradient(45deg, #2c3e50, #34495e);
  padding: 10px;
  border-radius: 5px;
  font-family: monospace;
  font-size: 12px;
  margin-top: 5px;
  word-break: break-all;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 20px;
`;

const Input = styled.input`
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #4a7ae3;
  background-color: #1a1a2e;
  color: #e0e0e0;
`;

const Select = styled.select`
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #4a7ae3;
  background-color: #1a1a2e;
  color: #e0e0e0;
`;

const ActionButton = styled(Button)`
  padding: 8px 16px;
  font-size: 14px;
`;

function DividendDistribution() {
  const navigate = useNavigate();
  const [merchants, setMerchants] = useState([
    { id: '001', name: 'TOY', vaultBalance: 100000, vaultAddress: '0x742d35Cc6634C0532925a3b844Bc454e4438f44e' },
    { id: '002', name: 'GPU', vaultBalance: 150000, vaultAddress: '0x3a54Cc6634C0532925a3b844Bc454e4438f77d9' },
    { id: '003', name: 'CPU', vaultBalance: 80000, vaultAddress: '0x1b2d35Cc6634C0532925a3b844Bc454e4438a3c1' },
    { id: '004', name: 'CMP', vaultBalance: 120000, vaultAddress: '0x5e7d35Cc6634C0532925a3b844Bc454e4438b2f4' },
    { id: '005', name: 'MEM', vaultBalance: 200000, vaultAddress: '0x9c1d35Cc6634C0532925a3b844Bc454e4438e5a7' },
  ]);
  const [selectedMerchant, setSelectedMerchant] = useState('');
  const [depositAmount, setDepositAmount] = useState('');

  const handleLogout = () => {
    navigate('/');
  };

  const handleDeposit = (e) => {
    e.preventDefault();
    setMerchants(merchants.map(merchant => 
      merchant.id === selectedMerchant
        ? { ...merchant, vaultBalance: merchant.vaultBalance + parseFloat(depositAmount) }
        : merchant
    ));
    setDepositAmount('');
    alert(`Deposited ${depositAmount} HKD for Merchant ${selectedMerchant}`);
  };

  const handleDistribute = (merchantId) => {
    const merchant = merchants.find(m => m.id === merchantId);
    alert(`Distributed ${merchant.vaultBalance} HKD to all PST token holders for Merchant ${merchantId}`);
    setMerchants(merchants.map(m => 
      m.id === merchantId
        ? { ...m, vaultBalance: 0 }
        : m
    ));
  };

  return (
    <DashboardContainer>
      <Sidebar>
        <Logo>QuVault</Logo>
        <Menu>
          <MenuItem><MenuLink href="/qupital">Dashboard</MenuLink></MenuItem>
          <MenuItem><MenuLink href="#" style={{color: '#4a7ae3'}}>Dividend Distribution</MenuLink></MenuItem>
        </Menu>
      </Sidebar>
      <MainContent>
        <Header>
          <Title>Dividend Distribution(#API NOT IMPLEMNT#)</Title>
          <UserInfo>
            <UserName>Admin User</UserName>
            <Button onClick={handleLogout}>Logout</Button>
          </UserInfo>
        </Header>
        <Card>
          <h3>Merchant Dividend Vaults(#API NOT IMPLEMNT#)</h3>
          <Table>
            <thead>
              <tr>
                <Th>Merchant ID</Th>
                <Th>Merchant Name</Th>
                <Th>Vault Balance (HKD)</Th>
                <Th>Vault Address</Th>
                <Th>Action</Th>
              </tr>
            </thead>
            <tbody>
              {merchants.map(merchant => (
                <tr key={merchant.id}>
                  <Td>{merchant.id}</Td>
                  <Td>{merchant.name}</Td>
                  <Td>{merchant.vaultBalance.toLocaleString()}</Td>
                  <Td>
                    <VaultAddress>{merchant.vaultAddress}</VaultAddress>
                  </Td>
                  <Td>
                    <ActionButton 
                      onClick={() => handleDistribute(merchant.id)}
                      disabled={merchant.vaultBalance === 0}
                    >
                      Distribute
                    </ActionButton>
                  </Td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card>
        <Card style={{ marginTop: '30px' }}>
          <h3>Deposit HKD Stablecoin(#API NOT IMPLEMNT#)</h3>
          <Form onSubmit={handleDeposit}>
            <Select 
              value={selectedMerchant} 
              onChange={(e) => setSelectedMerchant(e.target.value)}
              required
            >
              <option value="">Select Merchant</option>
              {merchants.map(merchant => (
                <option key={merchant.id} value={merchant.id}>
                  {merchant.name} ({merchant.id})
                </option>
              ))}
            </Select>
            <Input
              type="number"
              value={depositAmount}
              onChange={(e) => setDepositAmount(e.target.value)}
              placeholder="Amount in HKD"
              required
            />
            <Button type="submit">Deposit</Button>
          </Form>
        </Card>
      </MainContent>
    </DashboardContainer>
  );
}

export default DividendDistribution;