import styled from 'styled-components';

export const DashboardContainer = styled.div`
  display: flex;
  height: 100vh;
`;

export const Sidebar = styled.div`
  width: 200px; // Reduced from 250px
  background: linear-gradient(180deg, #1a1a2e, #16213e);
  padding: 20px; // Reduced from 30px
  box-shadow: 2px 0 10px rgba(0,0,0,0.1);
  transition: transform 0.3s ease-in-out;

`;

export const Logo = styled.div`
  font-size: 28px;
  font-weight: bold;
  color: #4a7ae3;
  margin-bottom: 40px;
  text-shadow: 0 0 10px rgba(74, 122, 227, 0.5);
`;

export const Menu = styled.ul`
  list-style-type: none;
  padding: 0;
`;

export const MenuItem = styled.li`
  margin-bottom: 20px;
`;

export const MenuLink = styled.a`
  text-decoration: none;
  color: #a0a0a0;
  font-size: 18px;
  transition: color 0.3s, text-shadow 0.3s;

  &:hover {
    color: #4a7ae3;
    text-shadow: 0 0 5px rgba(74, 122, 227, 0.5);
  }
`;

export const MainContent = styled.div`
  flex-grow: 1;
  padding: 40px;
  background: linear-gradient(135deg, #1a1a2e 0%, #2a2a4a 100%);
  overflow-y: auto;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
`;

export const Title = styled.h1`
  color: #e0e0e0;
`;

export const UserInfo = styled.div`
  display: flex;
  align-items: center;
`;

export const UserName = styled.span`
  margin-right: 20px;
  color: #e0e0e0;
`;

export const Button = styled.button`
  background: linear-gradient(45deg, #4a7ae3, #6a5acd);
  color: white;
  border: none;
  padding: 12px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: all 0.3s;
  font-family: 'Roboto Mono', monospace;

  &:hover {
    background: linear-gradient(45deg, #3a62b3, #5a4aad);
    box-shadow: 0 0 10px rgba(74, 122, 227, 0.5);
  }
`;

export const CardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 30px;
`;

export const Card = styled.div`
  background: linear-gradient(45deg, #16213e, #1e2a4a);
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
  }

  h3 {
    color: #e0e0e0;
    margin-bottom: 15px;
  }
`;

export const Statistic = styled.div`
  font-size: 32px;
  font-weight: bold;
  margin-top: 15px;
  color: #4a7ae3;
  text-shadow: 0 0 10px rgba(74, 122, 227, 0.3);
`;

export const LockedBalance = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin-top: 15px;
  color: #4a7ae3;
  text-shadow: 0 0 10px rgba(74, 122, 227, 0.3);
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 15px;
`;

export const Th = styled.th`
  padding: 15px;
  text-align: left;
  background-color: #1e2a4a;
  color: #4a7ae3;
`;

export const Td = styled.td`
  padding: 15px;
  text-align: left;
  background-color: #16213e;
  color: #e0e0e0;
`;

export const ChartPlaceholder = styled.div`
  height: 200px;
  background-color: #1e2a4a;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #4a7ae3;
  font-size: 18px;
  border-radius: 10px;
`;

export const ModalBackground = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
  `;