import {
    Card,
} from '../../DashboardStyles';
import { Table, ConfigProvider } from 'antd';
import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { message } from 'antd';
import api from '../../common/api';
import SubmitCredentialsModal from './SubmitCredentialsModal';
import Merchants from './Merchants';
import UnlockModal from './UnlockModal';
import DistributeDividensModal from './DistributeDividensModal';

const Button = styled.button`
  background: linear-gradient(45deg, #4a7ae3, #6a5acd);
  color: white;
  border: none;
  padding: 12px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 13px;
  transition: all 0.3s;
  font-family: 'Roboto Mono', monospace;
  width: 150px;
  &:hover {
    background: linear-gradient(45deg, #3a62b3, #5a4aad);
    box-shadow: 0 0 10px rgba(74, 122, 227, 0.5);
  }
  margin: 5px 5px;
`;

const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContent = styled.div`
  background: linear-gradient(45deg, #16213e, #1e2a4a);
  padding: 30px;
  border-radius: 10px;
  width: 500px;
`;

const ModalTitle = styled.h2`
  color: white;
  margin-bottom: 20px;
`;

const ActionButton = styled(Button)`
  margin: 5px;
  padding: 8px 16px;
  font-size: 14px;
  width: 100%;
`;

const Input = styled.input`
  width: 75%;
  padding: 10px;
  border-radius: 5px;
  box-sizing: border-box;
  border: 1px solid #4a7ae3;
  background-color: #1a1a2e;
  color: #e0e0e0;
`;

const LabelContainer = styled.div`
margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const ReadOnlyInput = styled(Input)`
  background-color: #2a2a3e;
  color: #e0e0e0;
`;

const PSTSymbolContainer = styled.div`
  display: flex;
  align-items: center;
  width: 75%;
`;

const PSTPrefix = styled.span`
  color: #e0e0e0;
  background-color: #1a1a2e;
  padding: 10px 0px 10px 10px;
  border: 1px solid #4a7ae3;
  border-right: none;
  border-radius: 5px 0 0 5px;
`;

const PSTInput = styled(Input)`
  flex: 1;
  border-left: none;
  border-radius: 0 5px 5px 0;
  padding-left: 0px;
`;


export default function StoreTable({ onTotalPSTIssuedChange, onActiveStoresChange, isAdmin = false }) {

    const [stores, setStores] = useState([]);
    const [showAddStoreModal, setShowAddStoreModal] = useState(false);
    const [showSubmitCredentialsModal, setShowSubmitCredentialsModal] = useState(false);
    const [showDistributeDividendsModal, setShowDistributeDividendsModal] = useState(false);

    const [addStoreProposalForm, setAddStoreProposalForm] = useState(
        {
            "store_name": "sample_store_name",
            "merchant_email": "merchant@email.com",
            "pst_symbol": "PST-LTD",
            "initial_valuation_per_pst": "20",
            "total_pst_amount": "10000",
            "ownership_merchant": 0.75,
            "ownership_liquidity_pool": 0.0,
            "ownership_investor_email1": "investor1@example.com",
            "investor1_ownership": 0.0,
            // "ownership_investor_email2": "investor1@example.com",
            // "investor2_ownership": 0.0,
            // "ownership_investor_email3": "investor1@example.com",
            // "investor3_ownership": 0.0
        }
    );

    const [selectedStore, setSelectedStore] = useState(null);
    const [marketCap, setMarketCap] = useState(0);
    const [openUnlockModal, setOpenUnlockModal] = useState(false);
    const [selectedMerchant, setSelectedMerchant] = useState({ id: "" });
    const columns = [
        { title: 'Store ID', dataIndex: 'id', key: 'store_id' },
        { title: 'Name', dataIndex: 'name', key: 'store_name' },
        { title: 'PST Symbol', dataIndex: 'pst_symbol', key: 'pst_symbol' },
        { title: "Status", dataIndex: "status", key: "status" },
        { title: 'Total PST Amount', dataIndex: ['agreement_parameters', 'total_pst_amount'], key: 'total_pst_amount' },
        { title: 'Initial Valuation Per PST', dataIndex: ['agreement_parameters', 'initial_valuation_per_pst'], key: 'initial_valuation_per_pst' },
        { title: "Liquidity Pool", dataIndex: ["agreement_parameters", "ownership", "liquidity_pool"], key: "liquidity_pool" },
        {
            title: 'Action',
            dataIndex: '',
            key: 'x',
            width: '180px',
            align: 'center',
            render: (store) => {
                return (
                    <div className='flex flex-col items-center'>
                        {
                            isAdmin && store.status === 'AWAITING_MINTING' &&
                            (
                                <Button onClick={() => handleMint(store)}>Mint PSTs</Button>
                            )
                        }
                        {
                            isAdmin && store.status === 'MINTED' &&
                            (
                                <Button onClick={() => handleReverseMint(store)}>Reverse Mint</Button>
                            )
                        }
                        {
                            !isAdmin && store.status === 'AWAITING_CREDENTIALS' &&
                            (
                                <Button onClick={() => handleOpenSubmitCredentialsModal(store)}>Submit Credentials</Button>
                            )
                        }
                        {
                            isAdmin &&
                            (
                                <Button onClick={() => handleOpenDistributeDividendsModal(store)}>
                                    Distribute
                                </Button>
                            )
                        }
                    </div>
                )
            },
        },
    ];

    const handleReverseMint = async (store) => {
        await api.post('/reverse-mint-psts', { storeId: store.id });
        message.success('PSTs reverse minted successfully');
        fetchStores();
    }
    const handleMint = async (store) => {
        await api.post('/mint-psts', {
            store_id: store.id,
        });
        message.success('PSTs minted successfully');
        fetchStores();
    }

    const handleOpenStoreModal = () => {
        setShowAddStoreModal(true);
    };

    const handleCloseStoreModal = () => {
        setShowAddStoreModal(false);
    }
    const handleOpenSubmitCredentialsModal = (store) => {
        setSelectedStore(store);
        setShowSubmitCredentialsModal(true);
    }
    const handleCloseSubmitCredentialsModal = () => {
        setSelectedStore(null);
        setShowSubmitCredentialsModal(false);
    }


    const handleUnlockActionSubmited = async () => {
        setOpenUnlockModal(false);
        fetchStores();
    }

    const handleAddStoreProposal = async () => {
        let data = {
            "store_name": addStoreProposalForm.store_name,
            "merchant_email": addStoreProposalForm.merchant_email,
            "pst_symbol": addStoreProposalForm.pst_symbol,
            "initial_valuation_per_pst": addStoreProposalForm.initial_valuation_per_pst,
            "total_pst_amount": addStoreProposalForm.total_pst_amount,
            "ownership": {
                "merchant": addStoreProposalForm.ownership_merchant,
                "liquidity_pool": addStoreProposalForm.ownership_liquidity_pool,
                "investors": {
                    [addStoreProposalForm.ownership_investor_email1]: addStoreProposalForm.investor1_ownership,
                    // [addStoreProposalForm.ownership_investor_email2]: addStoreProposalForm.investor2_ownership,
                    // [addStoreProposalForm.ownership_investor_email3]: addStoreProposalForm.investor3_ownership
                }
            }
        }
        await api.post('/add-store-proposal', data);
        message.success('Store proposal submitted successfully');
    }


    const handleAddStoreProposalFormChange = (e) => {
        const { name, value } = e.target;
        setAddStoreProposalForm({ ...addStoreProposalForm, [name]: value });
    };
    const fetchStores = async () => {
        let url = isAdmin ? "/get-stores" : "/get-my-stores";
        let res = await api.get(url);
        setStores(res.data.stores);
        let totalPST = 0;
        let activeStores = 0;
        for (let store of res.data.stores) {
            if (store.status === 'MINTED') {
                activeStores += 1;
                totalPST += store?.agreement_parameters?.total_pst_amount * store?.agreement_parameters?.initial_valuation_per_pst;
            }
        }
        onActiveStoresChange?.(activeStores);
        onTotalPSTIssuedChange?.(totalPST.toLocaleString());
    };

    const handleUnlockAction = (merchantId) => {
        setSelectedMerchant({ id: merchantId });
        setOpenUnlockModal(true);
    }

    const handlePSTSymbolChange = (e) => {
        const value = e.target.value.toUpperCase();
        handleAddStoreProposalFormChange({
            target: { name: 'pst_symbol', value: `PST-${value}` }
        });
    };

    const handleOpenDistributeDividendsModal = (store) => {
        setSelectedStore(store);
        setShowDistributeDividendsModal(true);
    }

    const handleCloseDistributeDividendsModal = () => {
        setSelectedStore(null);
        setShowDistributeDividendsModal(false);

    }

    const handleDistributeActionSubmited = () => {
        setShowDistributeDividendsModal(false);
        fetchStores();
    }

    useEffect(() => {
        fetchStores();
    }, []);


    useEffect(() => {
        const initialValuation = parseFloat(addStoreProposalForm.initial_valuation_per_pst) || 0;
        const totalAmount = parseFloat(addStoreProposalForm.total_pst_amount) || 0;
        setMarketCap(initialValuation * totalAmount);
    }, [addStoreProposalForm.initial_valuation_per_pst, addStoreProposalForm.total_pst_amount]);


    return (
        <>
            <Card style={{ marginTop: '30px' }}>
                <div className="flex justify-between items-center">
                    <h3>Store Monitoring</h3>
                    {
                        isAdmin &&
                        (
                            <Button onClick={handleOpenStoreModal}>Add Store Proposal</Button>
                        )
                    }

                </div>
                <ConfigProvider theme={{
                    components: {
                        Table: {
                            headerBg: "#1e2a4a",
                            bodySortBg: "#1e2a4a",
                            footerBg: "#1e2a4a",
                            colorBgContainer: "#16213e",
                            borderColor: "transparent",
                            headerSplitColor: "transparent",
                            headerColor: "#e0e0e0",
                            colorText: "#e0e0e0",
                            colorTextDescription: "#e0e0e0"
                        }
                    }
                }}>
                    <Table
                        expandable={{
                            expandedRowRender: (stores) => (
                                <div className="flex flex-col">
                                    <div className='font-bold'>Merchants Mangemnet</div>
                                    <Merchants onUnlockAction={handleUnlockAction} merchants={stores.agreement_parameters.ownership.merchant} isAdmin={isAdmin}></Merchants>
                                </div>
                            ),
                            rowExpandable: (store) => { return !!store?.agreement_parameters?.ownership },
                        }}
                        pagination={false}
                        // @ts-ignore
                        rowKey={(store) => store.id} columns={columns} dataSource={stores}
                        className='mt-[20px]'>
                    </Table>
                </ConfigProvider>
            </Card >
            {
                showDistributeDividendsModal && (
                    <DistributeDividensModal store={selectedStore} onClose={handleCloseDistributeDividendsModal} onSubmited={handleDistributeActionSubmited}></DistributeDividensModal>
                )
            }
            {openUnlockModal && (<UnlockModal merchant={selectedMerchant} onClose={() => { setOpenUnlockModal(false) }} onSubmited={handleUnlockActionSubmited}></UnlockModal>)}
            {
                showSubmitCredentialsModal && (
                    <SubmitCredentialsModal onSubmited={fetchStores} onClose={handleCloseSubmitCredentialsModal} store={selectedStore}></SubmitCredentialsModal>
                )
            }
            {
                showAddStoreModal && (
                    <ModalBackground onClick={handleCloseStoreModal}>
                        <ModalContent onClick={(e) => e.stopPropagation()}>
                            <ModalTitle>Add Store Proposal</ModalTitle>
                            <LabelContainer>
                                <label style={{ color: '#fff' }} htmlFor="Store Name">StoreName:</label>
                                <Input name="store_name" value={addStoreProposalForm.store_name} onChange={handleAddStoreProposalFormChange} type="text" placeholder="" required />
                            </LabelContainer>

                            <LabelContainer>
                                <label style={{ color: '#fff' }} htmlFor="Merchant Email">Merchant Email:</label>
                                <Input name="merchant_email" value={addStoreProposalForm.merchant_email} onChange={handleAddStoreProposalFormChange} type="email" placeholder="" required />
                            </LabelContainer>

                            <LabelContainer>
                                <label style={{ color: '#fff' }} htmlFor="PST Symbol">PST Symbol:</label>
                                <PSTSymbolContainer>
                                    <PSTPrefix>PST-</PSTPrefix>
                                    <PSTInput
                                        name="pst_symbol"
                                        value={addStoreProposalForm.pst_symbol.replace('PST-', '')}
                                        onChange={handlePSTSymbolChange}
                                        type="text"
                                        placeholder=""
                                        required
                                    />
                                </PSTSymbolContainer>
                            </LabelContainer>

                            <LabelContainer>
                                <label style={{ color: '#fff' }} htmlFor="Initial Valuation per PST">Initial Valuation per PST (sHKD):</label>
                                <Input name="initial_valuation_per_pst" value={addStoreProposalForm.initial_valuation_per_pst} onChange={handleAddStoreProposalFormChange} type="number" placeholder="" required />
                            </LabelContainer>

                            <LabelContainer>
                                <label style={{ color: '#fff' }} htmlFor="Total PST Amount">Total PST Amount:</label>
                                <Input name="total_pst_amount" value={addStoreProposalForm.total_pst_amount} onChange={handleAddStoreProposalFormChange} type="number" placeholder="" required />
                            </LabelContainer>

                            <LabelContainer>
                                <label style={{ color: '#fff' }} htmlFor="Market Cap">Market Cap (sHKD):</label>
                                <ReadOnlyInput value={marketCap.toFixed(2)} type="text" readOnly />
                            </LabelContainer>

                            <LabelContainer>
                                <label style={{ color: '#fff' }} htmlFor="Merchant Ownership">Merchant Ownership:</label>
                                <Input name="ownership_merchant" value={addStoreProposalForm.ownership_merchant} onChange={handleAddStoreProposalFormChange} type="number" placeholder="" required />
                            </LabelContainer>

                            <LabelContainer>
                                <label style={{ color: '#fff' }} htmlFor="Liquidity Pool Ownership ">Liquidity Pool Ownership :</label>
                                <Input name="ownership_liquidity_pool" value={addStoreProposalForm.ownership_liquidity_pool} onChange={handleAddStoreProposalFormChange} type="number" placeholder="" required />
                            </LabelContainer>

                            <LabelContainer>
                                <label style={{ color: '#fff' }} htmlFor="Investor1 Email">Investor 1 Email:</label>
                                <Input name="ownership_investor_email1" value={addStoreProposalForm.ownership_investor_email1} onChange={handleAddStoreProposalFormChange} type="email" placeholder="" required />
                            </LabelContainer>

                            <LabelContainer>
                                <label style={{ color: '#fff' }} htmlFor="Investor1 Ownership">Investor 1 Ownership:</label>
                                <Input name="investor1_ownership" value={addStoreProposalForm.investor1_ownership} onChange={handleAddStoreProposalFormChange} type="number" placeholder="" required />
                            </LabelContainer>

                            <ActionButton onClick={() => handleAddStoreProposal()}>
                                Submit
                            </ActionButton>
                        </ModalContent>
                    </ModalBackground>
                )
            }

        </>
    );
}