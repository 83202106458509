// @ts-nocheck
import {
    Card,
} from '../../DashboardStyles';
import { Table, ConfigProvider } from 'antd';
import { useState, useEffect } from 'react';
import api from '../../common/api';
import UnlockModal from './UnlockModal';
import styled from 'styled-components';
export const Button = styled.button`
  background: linear-gradient(45deg, #4a7ae3, #6a5acd);
  color: white;
  border: none;
  padding: 12px;
  width: 100px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 12px;
  transition: all 0.3s;
  font-family: 'Roboto Mono', monospace;
  margin: 0 5px;
  &:hover {
    background: linear-gradient(45deg, #3a62b3, #5a4aad);
    box-shadow: 0 0 10px rgba(74, 122, 227, 0.5);
  }
`;
export default function Merchants({ merchants, isAdmin, onUnlockAction }) {
    const [merchantsList, setMerchantsList] = useState([]);

    const columns = [
        { title: 'Merchant ID', dataIndex: 'id', key: 'id', align: 'center', },
        { title: 'Merchant UserName', dataIndex: 'username', key: 'username', align: 'center', },
        { title: 'Merchant Email', dataIndex: 'email', key: 'email', align: 'center', },
        { title: 'Merchant Share', dataIndex: 'share', key: 'share', align: 'center', },
        { title: "Merchant Status", dataIndex: "status", key: "status", align: 'center', },

    ];
    const handleUnlockAction = async (merchantId) => {
        onUnlockAction?.(merchantId);
    };

    if (isAdmin) {
        columns.push({
            title: 'Action',
            dataIndex: '',
            key: 'x',
            align: 'center',
            width: '190px',
            render: (merchant) => {
                return (
                    <div className='flex item-center'>
                        <Button onClick={() => { handleFreezeAction(merchant.id) }}>{merchant.isFrozen ? 'Un Freeze' : 'Freeze'}</Button>
                        <Button onClick={() => { handleUnlockAction(merchant.id) }}>Unlock PSTs</Button>
                    </div>
                )
            },
        });
    }
    const handleFreezeAction = async (merchantId) => {
        let res = await api.post("/toggle-merchant-freeze", { "merchant_id": merchantId });
        setMerchantsList(prevMerchantsList => {
            return prevMerchantsList.map(merchant => {
                if (merchant.id === merchantId) {
                    return {
                        ...merchant,
                        status: res?.data?.owner?.permissions?.isFrozen ? "Frozen" : "Un Frozen",
                        isFrozen: res?.data?.owner?.permissions?.isFrozen
                    };
                }
                return merchant;
            });
        });
    }




    useEffect(() => {
        let newList = [];
        for (let id in merchants) {
            let status = merchants[id].isFrozen ? "Frozen" : "Un Frozen";
            newList.push({ status, id, ...merchants[id] });
        }
        setMerchantsList(newList);
    }, [merchants]);

    return (
        <>
            <Card style={{ marginTop: '30px' }}>
                <ConfigProvider theme={{
                    components: {
                        Table: {
                            headerBg: "#1e2a4a",
                            bodySortBg: "#1e2a4a",
                            footerBg: "#1e2a4a",
                            colorBgContainer: "#16213e",
                            borderColor: "transparent",
                            headerSplitColor: "transparent",
                            headerColor: "#e0e0e0",
                            colorText: "#e0e0e0",
                            colorTextDescription: "#e0e0e0"
                        }
                    }
                }}>
                    <Table
                        pagination={false}
                        rowKey={(merchant) => merchant.id} columns={columns} dataSource={merchantsList}
                        className='mt-[20px]'>
                    </Table>
                </ConfigProvider>
            </Card >

        </>
    )
}