import axios from 'axios';
import { message } from 'antd';
import config from './config';
const api = axios.create({
    baseURL: config?.API_URL || "/api",
    headers: {
        'Content-Type': 'application/json',
        'apikey': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImFkdGVqYXJxaXZzbXBieGFpcGFuIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjEzOTU0MjIsImV4cCI6MjAzNjk3MTQyMn0.QAeWRcv95KfKl4V607uQiT1j8knLpnz2ZMG0dvbmRNU'
    }
});

// add token before each request
api.interceptors.request.use(async (config) => {
    let token = localStorage.getItem('token') || "";
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    // loading effect
    let data = config.data || {};
    let loadingText = data?.loadingText || "Loading";
    if (!data?.hideLoading) message.loading({ content: loadingText, key: 'loading', duration: 0 });
    return config;
});

// check there is an error in response
api.interceptors.response.use(async (response) => {
    message.destroy('loading');
    return response;
}, (error) => {
    // 401 unauthorized, redirect to login page
    message.destroy("loading");
    if (error.response.status === 401) {
        localStorage.setItem("token", "");
        window.location.href = "/";
        // Please login.
        message.error({ content: 'Please login.', key: 'error', duration: 2 });
        return Promise.reject(error);
    }
    let errorMessage = error?.response?.data?.error || 'Request failed, check your network.';
    message.error({content: errorMessage, key: 'error', duration: 2});
    return Promise.reject(error);
});

export default api;