import {
    Button,
    ModalBackground
} from '../../DashboardStyles';

import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { message } from 'antd';
import api from '../../common/api';



const ModalContent = styled.div`
    background: linear-gradient(45deg, #16213e, #1e2a4a);
    padding: 30px;
    border-radius: 10px;
    width: 500px;
  `;

const ModalTitle = styled.h2`
    color: white;
    margin-bottom: 20px;
  `;

const ActionButton = styled(Button)`
    margin: 5px;
    padding: 8px 16px;
    font-size: 14px;
    width: 100%;
  `;

const Input = styled.input`
    width: 75%;
    padding: 10px;
    border-radius: 5px;
    box-sizing: border-box;
    border: 1px solid #4a7ae3;
    background-color: #1a1a2e;
    color: #e0e0e0;
  `;

const LabelContainer = styled.div`
  margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  `;
export default function DistributeDividensModal({ onClose, store, onSubmited }) {

    const [dividendAmount, setDividendAmount] = useState('1');
    const [dividendCurrencySymbol, setDividendCurrencySymbol] = useState('sHKD');

    const handleSubmit = async () => {
        let params = {
            "storeId": store.id,
            "dividendAmount": dividendAmount,
            "dividendCurrencySymbol": dividendCurrencySymbol
        }
        await api.post('/distribute-dividends', params);
        message.success('Dividends distributed successfully');
        onSubmited?.();
        handleClose();
    };

    const handleClose = () => { onClose?.(); };
    return (
        <ModalBackground onClick={handleClose}>
            <ModalContent onClick={(e) => e.stopPropagation()}>
                <ModalTitle>Distribute Dividens</ModalTitle>
                <LabelContainer>
                    <label style={{ color: '#fff' }}>Dividend Amount:</label>
                    <Input value={dividendAmount} onChange={(e) => { setDividendAmount(e.target.value); }} type="number" placeholder="" required />
                </LabelContainer>
                <LabelContainer>
                    <label style={{ color: '#fff' }}>Dividend Currency Symbol:</label>
                    <Input value={dividendCurrencySymbol} onChange={(e) => { setDividendCurrencySymbol(e.target.value) }} type="text" placeholder="" required />
                </LabelContainer>
                <ActionButton onClick={() => handleSubmit()}>
                    Submit
                </ActionButton>
            </ModalContent>
        </ModalBackground>
    );
};