// @ts-check
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  DashboardContainer,
  Sidebar,
  Logo,
  Menu,
  MenuItem,
  MenuLink,
  MainContent,
  Header,
  Title,
  UserInfo,
  UserName,
  Button,
  CardContainer,
  Card,
  Statistic,
} from '../DashboardStyles';
import utils from '../common/utils';
import StoreTable from '../components/StoreTable';

function MerchantDashboard() {
  const [user, setUser] = useState({});

  const navigate = useNavigate();

  const handleLogout = () => {
    navigate('/');
  };


  useEffect(() => {
    let user = utils.getValue('user');
    if (!user) {
      navigate('/');
    }
    setUser(user);
  }, [navigate]);

  return (
    <DashboardContainer>
      <Sidebar>
        <Logo>QuVault</Logo>
        <Menu>
          <MenuItem><MenuLink href="/merchant">Dashboard</MenuLink></MenuItem>
          <MenuItem><MenuLink href="/merchant/wallet">Wallet</MenuLink></MenuItem>
        </Menu>
      </Sidebar>
      <MainContent>
        <Header>
          <Title>Merchant Dashboard</Title>
          <UserInfo>
            {/* @ts-ignore */}
            <UserName>{user?.username}</UserName>
            <Button onClick={handleLogout}>Logout</Button>
          </UserInfo>
        </Header>
        <CardContainer>
          <Card>
            <h3>PST Balance(NO API PROVIDED)</h3>
            <Statistic>1,000 PST</Statistic>
          </Card>
          <Card>
            <h3>Dividends Earned(NO API PROVIDED)</h3>
            <Statistic>HK$500,000</Statistic>
          </Card>
        </CardContainer>
        <Card style={{ marginTop: '30px' }}>
          <h3>Web2 Assets(NO API PROVIDED)</h3>
          <Button>Access Amazon Account</Button>
          <Button style={{ marginLeft: '15px' }}>Access Email Account</Button>
        </Card>
        {/* @ts-ignore */}
        <StoreTable></StoreTable>
      </MainContent>
    </DashboardContainer>
  );
}

export default MerchantDashboard;