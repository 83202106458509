import React, { useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import api from '../common/api';
import { message } from 'antd';

const SignUpContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(135deg, #0f0c29, #302b63, #24243e);
`;

const SignUpCard = styled.div`
  background: linear-gradient(45deg, #16213e, #1e2a4a);
  padding: 40px;
  border-radius: 10px;
  width: 300px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
`;

const Logo = styled.h2`
  font-size: 28px;
  font-weight: bold;
  color: #4a7ae3;
  text-align: center;
  margin-bottom: 40px;
  text-shadow: 0 0 10px rgba(74, 122, 227, 0.5);
`;

const SignUpButton = styled.button`
  width: 100%;
  margin-top: 20px;
  background: linear-gradient(45deg, #4a7ae3, #6a5acd);
  color: white;
  border: none;
  padding: 12px 24px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: all 0.3s;

  &:hover {
    background: linear-gradient(45deg, #3a62b3, #5a4aad);
    box-shadow: 0 0 10px rgba(74, 122, 227, 0.5);
  }
`;

const BackButton = styled.button`
  width: 100%;
  margin-top: 20px;
  background: linear-gradient(45deg, #4a7ae3, #6a5acd);
  color: white;
  border: none;
  padding: 12px 24px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: all 0.3s;

  &:hover {
    background: linear-gradient(45deg, #3a62b3, #5a4aad);
    box-shadow: 0 0 10px rgba(74, 122, 227, 0.5);
  }
`;

const Input = styled.input`
  width: 100%;
  padding: 12px 24px;
  border-radius: 5px;
  margin-top: 20px;
  border: none;
  box-sizing: border-box;
  border: 1px solid #4a7ae3;
  background-color: #1a1a2e;
  color: #e0e0e0;
`;

function SignUp() {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [username, setUsername] = useState('');

  const handleSignUp = () => {
    api.post("/signup-owner", {
      email: email,
      password: password,
      username: username,
      loadingText: 'Please wait..'
    }).then(res => {
      message.success('Sign up successful');
      navigate('/');
    });
  };

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <SignUpContainer>
      <SignUpCard>
        <Logo>Sign Up</Logo>
        <Input value={username} name="username" onChange={e => setUsername(e.target.value)} placeholder='Input your username'></Input>
        <Input value={email} name="email" onChange={e => setEmail(e.target.value)} placeholder='Input your email'></Input>
        <Input value={password} name="password" type="password" onChange={e => setPassword(e.target.value)} placeholder='Input your password'></Input>
        <SignUpButton onClick={handleSignUp}>Sign up now!</SignUpButton>
        <BackButton onClick={handleBack}>Back</BackButton>
      </SignUpCard>
    </SignUpContainer>
  );
}

export default SignUp;