const config = {
    defaultColor: "#4a7ae3",
    currencys: {
        sHKD: {
            title: "HKD Stablecoin",
            color: "#16a085"
        },
        BTC: {
            title: "Bitcoin",
        },
        ETH: {
            title: "Ethereum",
        },
        USDT: {
            title: "Tether Stablecoin",
        },
        USDC: {
            title: "Circle Stablecoin",
            color: "#2980b9"
        },
        OM: {
            title: "Mantra",
        },
        PST: {
            title: "PST Balance",
            color: "#4a7ae3"
        }
    },
    // API_URL: "https://adtejarqivsmpbxaipan.supabase.co/functions/v1/"
};

export default config;