import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  DashboardContainer,
  Sidebar,
  Logo,
  Menu,
  MenuItem,
  MenuLink,
  MainContent,
  Header,
  Title,
  UserInfo,
  UserName,
  Button,
  CardContainer,
  Card,
  Statistic,
} from '../DashboardStyles';
import utils from '../common/utils';
import StoreTable from '../components/StoreTable';

// @ts-ignore
function QupitalDashboard() {
  const navigate = useNavigate();
  const [user, setUser] = useState({});
  const [activeStores, setActiveStores] = useState(0);
  const [totalPSTIssued, setTotalPSTIssued] = useState("0");


  useEffect(() => {
    let user = utils.getValue('user');
    if (!user) {
      navigate('/');
    }
    setUser(user);
  }, [navigate]);

  const handleLogout = () => {
    navigate('/');
  };

  return (
    <DashboardContainer>
      <Sidebar>
        <Logo>QuVault</Logo>
        <Menu>
          <MenuItem><MenuLink href="#" style={{ color: '#4a7ae3' }}>Dashboard</MenuLink></MenuItem>
          <MenuItem><MenuLink href="/qupital/dividend">Dividend Distribution</MenuLink></MenuItem>
        </Menu>
      </Sidebar>
      <MainContent>
        <Header>
          <Title>Qupital Admin Dashboard</Title>
          <UserInfo>
            {/* @ts-ignore */}
            <UserName>{user?.username || "loading"}</UserName>
            <Button onClick={handleLogout}>Logout</Button>
          </UserInfo>
        </Header>
        <CardContainer>
          <Card>
            <h3>Active Stores</h3>
            <Statistic>{activeStores}</Statistic>
          </Card>
          <Card>
            <h3>Total PST Issued</h3>
            <Statistic>HK${totalPSTIssued}</Statistic>
          </Card>
        </CardContainer>
        {
          // @ts-ignore
          user?.id &&
          (
            <StoreTable isAdmin={true} onActiveStoresChange={(e) => { setActiveStores(e) }} onTotalPSTIssuedChange={(e) => setTotalPSTIssued(e)}></StoreTable>
          )
        }
      </MainContent>
    </DashboardContainer>
  );
}

export default QupitalDashboard;