// @ts-nocheck
import {
    Card,
} from '../../DashboardStyles';
import { Table, ConfigProvider } from 'antd';
import { useState, useEffect, useRef } from 'react';
import ExchangeModal from './ExchangeModal';
import DepositModal from './DepositModal';
import WithdrawModal from './WithdrawModal';
import api from '../../common/api';
import styled from 'styled-components';

const Button = styled.button`
  background: linear-gradient(45deg, #4a7ae3, #6a5acd);
  color: white;
  border: none;
  padding: 12px 12px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 12px;
  transition: all 0.3s;
  font-family: 'Roboto Mono', monospace;

  &:hover {
    background: linear-gradient(45deg, #3a62b3, #5a4aad);
    box-shadow: 0 0 10px rgba(74, 122, 227, 0.5);
  }
`;
export default function LiquidityPools({onUpdate}) {
    

    const [pools, setPools] = useState([]);
    const [showExchangeModal, setShowExchangeModal] = useState(false);
    const [selectedPool, setSelectedPool] = useState(null);
    const [showDepositModal, setShowDepositModal] = useState(false);
    const [showWithdrawModal, setShowWithdrawModal] = useState(false);
    const handleExchangeModalOpen = (pool) => {
        setSelectedPool(pool);
        setShowExchangeModal(true);
    }
    const handleDepositModalOpen = (pool) => {
        setSelectedPool(pool);
        setShowDepositModal(true);

    }
    const handleWithdrawModalOpen = (pool) => {
        setSelectedPool(pool);
        setShowWithdrawModal(true);
    }
    const handleModalClose = () => {
        setShowExchangeModal(false);
        setShowDepositModal(false);
        setShowWithdrawModal(false);
    }
    const columns = [
        { title: 'Store Name', dataIndex: ['stores', 'name'], key: 'store_name', align: 'center', },
        { title: 'PST symbol', dataIndex: 'pst_symbol', key: 'pst_symbol', align: 'center', },
        { title: "PST Reserve", dataIndex: 'pst_reserve', key: 'pst_reserve', align: 'center', },
        { title: 'Currency Symbol', dataIndex: 'currency_symbol', key: 'currency_symbol', align: 'center', },
        { title: 'Currency Reserve', dataIndex: 'currency_reserve', key: 'currency_reserve', align: 'center', },
        { title: "Total Ownership", dataIndex: "total_ownership", key: "total_ownership", align: 'center', },
        { title: "Transaction Fee Factor", dataIndex: "transaction_fee_factor", key: "transaction_fee_factor", align: 'center', },
        {
            title: "Action",
            dataIndex: '',
            key: 'x',
            align: 'center',
            width: '350px',
            render: (pool) => {
                return (
                    <div className='flex flex-row items-center justify-around'>
                        <Button onClick={() => { handleExchangeModalOpen(pool) }}>Exchange</Button>
                        <Button onClick={() => { handleDepositModalOpen(pool) }}>Deposit</Button>
                        <Button onClick={() => { handleWithdrawModalOpen(pool) }}>Withdraw</Button>
                    </div>
                )
            },
        }
    ];

    const handleSubmit = () => {
        handleModalClose();
        onUpdate?.();
        fetchLiquiditPools();
    }

    const fetchLiquiditPools = async () => {
        try {
            let res = await api.get("/get-liquidity-pools");
            setPools(res.data);
        } catch (e) {
            console.error(e);
        }
    }

    useEffect(() => {
        fetchLiquiditPools();
    }, []);

    return (
        <>
            <Card style={{ marginTop: '30px' }}>
                <h3>Available Liquidity Pools</h3>
                <ConfigProvider theme={{
                    components: {
                        Table: {
                            headerBg: "#1e2a4a",
                            bodySortBg: "#1e2a4a",
                            footerBg: "#1e2a4a",
                            colorBgContainer: "#16213e",
                            borderColor: "transparent",
                            headerSplitColor: "transparent",
                            headerColor: "#e0e0e0",
                            colorText: "#e0e0e0",
                            colorTextDescription: "#e0e0e0"
                        }
                    }
                }}>
                    <Table
                        pagination={false}
                        rowKey={(pool) => pool.id} columns={columns} dataSource={pools}
                        className='mt-[20px]'>
                    </Table>
                </ConfigProvider>
            </Card >
            {
                showExchangeModal && <ExchangeModal onClose={handleModalClose} pool={selectedPool} onSubmited={handleSubmit}></ExchangeModal>
            }
            {
                showDepositModal && <DepositModal onClose={handleModalClose} pool={selectedPool} onSubmited={handleSubmit}></DepositModal>
            }
            {
                showWithdrawModal && <WithdrawModal onClose={handleModalClose} pool={selectedPool} onSubmited={handleSubmit}></WithdrawModal>
            }
        </>
    )
};
