import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@400;700&display=swap');

  body, html {
    margin: 0;
    padding: 0;
    font-family: 'Roboto Mono', monospace;
    height: 100%;
  }

  #root {
    height: 100%;
  }
`;

export default GlobalStyles;