import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  DashboardContainer,
  Sidebar,
  Logo,
  Menu,
  MenuItem,
  MenuLink,
  MainContent,
  Header,
  Title,
  UserInfo,
  UserName,
  Button,
} from '../DashboardStyles';
import utils from '../common/utils';
import Wallet from '../components/Wallet';
import Transaction from '../components/Transaction';
import LiquidityPools from '../components/LiquidityPools';

function InvestorWallet() {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);

  const handleLogout = () => {
    navigate('/');
  };


  useEffect(() => {
    setUser(utils.getValue('user'));
  }, []);



  return (
    <DashboardContainer>
      <Sidebar>
        <Logo>QuVault</Logo>
        <Menu>
          <MenuItem><MenuLink href="/investor">Dashboard</MenuLink></MenuItem>
          <MenuItem><MenuLink href="#" style={{ color: '#4a7ae3' }}>Wallet</MenuLink></MenuItem>
        </Menu>
      </Sidebar>
      <MainContent>
        <Header>
          <Title>Investor Wallet</Title>
          <UserInfo>
            <UserName>{user?.username}</UserName>
            <Button onClick={handleLogout}>Logout</Button>
          </UserInfo>
        </Header>
        <Wallet></Wallet>
        {/* @ts-ignore */}
        <LiquidityPools></LiquidityPools>
        <Transaction></Transaction>
      </MainContent>
    </DashboardContainer>
  );
}

export default InvestorWallet;