// @ts-nocheck
import {
    Button,
    CardContainer,
    Card,
    Statistic,
    LockedBalance
} from '../../DashboardStyles';
import styled from 'styled-components';
import { useState, useEffect } from 'react';
import api from '../../common/api';
import config from '../../common/config';

const ModalContent = styled.div`
  background: linear-gradient(45deg, #16213e, #1e2a4a);
  padding: 30px;
  border-radius: 10px;
  width: 300px;
`;

const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
`;

const Select = styled.select`
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  border: 1px solid #4a7ae3;
  background-color: #1a1a2e;
  color: #e0e0e0;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 5px;
  box-sizing: border-box;
  border: 1px solid #4a7ae3;
  background-color: #1a1a2e;
  color: #e0e0e0;
`;
const AssetCard = styled(Card)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const AssetLogo = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 25px;
  background-color: ${props => props.color};
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  margin-bottom: 10px;
`;

const AssetActions = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  gap: 10px;
`;

const ActionButton = styled(Button)`
  padding: 8px 16px;
  font-size: 14px;
`;

function WalletCard({ wallet, onOpenModal }) {
    let color = config.currencys?.[wallet.currencySymbol]?.color || "#4a7ae3";
    let title = config.currencys?.[wallet.currencySymbol]?.title || "Unknown Currency:" + wallet.currencySymbol;
    return (
        <AssetCard>
            <AssetLogo color={color}>{wallet.currencySymbol}</AssetLogo>
            <h3>{title}</h3>
            <Statistic>{wallet.availableBalance} {wallet.currencySymbol}</Statistic>
            <LockedBalance>Locked: ({wallet.lockedBalance})</LockedBalance>
            <AssetActions>
                <ActionButton onClick={() => onOpenModal?.('Receive', wallet)}>Receive</ActionButton>
                <ActionButton onClick={() => onOpenModal?.('Send', wallet)}>Send</ActionButton>
                <ActionButton onClick={() => onOpenModal?.('Swap', wallet)}>Swap</ActionButton>
            </AssetActions>
        </AssetCard>
    );
}


function Wallet() {
    const [modalOpen, setModalOpen] = useState(false);
    const [wallets, setWallets] = useState([]);
    const [modalAction, setModalAction] = useState('');
    const [modalAsset, setModalAsset] = useState({});
    const [swapTarget, setSwapTarget] = useState('');
    const [recipient, setRecipient] = useState('');
    const [amount, setAmount] = useState('');
    const fetchWallets = async () => {
        let res = await api.get("/get-wallets");
        setWallets(res.data);
    };
    const openModal = (action, asset) => {
        setModalAction(action);
        setModalAsset(asset);
        setModalOpen(true);
    };
    const closeModal = () => {
        setModalOpen(false);
    };
    const handleTransaction = (e) => {
        e.preventDefault();
        // Here you would handle the actual transaction
        console.log(`${modalAction} ${modalAsset} ${swapTarget}`);
        if (modalAction === 'Send') {
            let data = {
                'recipient_wallet_id': recipient,
                'amount': amount,
                'currency_symbol': modalAsset.currencySymbol,
                'chain_symbol': modalAsset.chainSymbol
            };
            api.post('/send-transfer', data).then(res => {
                alert('Transaction sent successfully');
            });
        }

        closeModal();
    };
    useEffect(() => {
        fetchWallets();
    }, []);
    return (
        <>
            <CardContainer>
                {
                    wallets.map((item, index) => {
                        return (
                            <WalletCard key={index} wallet={item} onOpenModal={openModal}></WalletCard>
                        )
                    })
                }
            </CardContainer>
            {modalOpen && (
                <ModalBackground onClick={closeModal}>
                    <ModalContent onClick={(e) => e.stopPropagation()}>
                        <h2 style={{ color: '#fff' }}>{modalAction} {modalAsset.currencySymbol}</h2>
                        <form onSubmit={handleTransaction}>
                            {modalAction === 'Send' && (
                                <>
                                    <label style={{ color: '#fff' }} htmlFor="recipient">Recipient Address:</label>
                                    <Input value={recipient} onChange={(e) => { setRecipient(e.target.value) }} type="text" placeholder="Recipient Address" id="recipient" required />
                                    <label style={{ color: '#fff' }} htmlFor="amount">Amount:</label>
                                    <Input value={amount} onChange={(e) => { setAmount(e.target.value) }} type="number" placeholder="Amount" id="amount" required />
                                </>
                            )}
                            {modalAction === 'Receive' && (
                                <>
                                    <label style={{ color: '#fff' }} htmlFor="address">Your Wallet Address:</label>
                                    <Input type="text" value={modalAsset?.walletAddress} id="address" readOnly />
                                </>
                            )}
                            {modalAction === 'Swap' && (
                                <>
                                    <label style={{ color: '#fff' }} htmlFor="swap-amount">Amount to Swap:</label>
                                    <Input type="number" placeholder="Amount" id="swap-amount" required />
                                    <label style={{ color: '#fff' }} htmlFor="target-asset">Target Asset:</label>
                                    <Select value={swapTarget} onChange={(e) => setSwapTarget(e.target.value)} id="target-asset" required>
                                        <option value="" disabled>Select Target Asset</option>
                                        <option value="HKD">HKD Stablecoin</option>
                                        <option value="USDC">USDC</option>
                                    </Select>
                                </>
                            )}
                            <Button type="submit">{modalAction}</Button>
                        </form>
                    </ModalContent>
                </ModalBackground>
            )}
        </>
    );

}
export default Wallet;