import React, {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import {
  DashboardContainer,
  Sidebar,
  Logo,
  Menu,
  MenuItem,
  MenuLink,
  MainContent,
  Header,
  Title,
  UserInfo,
  UserName,
  Button,
  CardContainer,
  Card,
  Statistic,
  Table,
  Th,
  Td,
  ChartPlaceholder
} from '../DashboardStyles';
import styled from 'styled-components';
import utils from '../common/utils';
import api from '../common/api';

const SmallCard = styled(Card)`
  h3 {
    font-size: 1em;
    margin-bottom: 5px;
  }
`;

const DividendTable = styled(Table)`
  margin-top: 20px;
`;


const loadWallet = async () => {
  let res = await api.get("/get-wallets");
  console.log(res);
};

function InvestorDashboard() {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.setItem("token", "");
    navigate('/');
  };

  const [user, setUser] = useState({});


  useEffect(()=>{
    let user = utils.getValue('user');
    if (!user) {
      navigate('/');
    }
    setUser(user);

    loadWallet();
  },[navigate])




  const dummyDividendHistory = [
    { date: '2024-06-01', amount: '$150', apy: '14.5%' },
    { date: '2024-05-01', amount: '$145', apy: '14.2%' },
    { date: '2024-04-01', amount: '$155', apy: '14.8%' },
    { date: '2024-03-01', amount: '$140', apy: '13.9%' },
  ];

  return (
    <DashboardContainer>
      <Sidebar>
        <Logo>QuVault</Logo>
        <Menu>
          <MenuItem><MenuLink href="#" style={{color: '#4a7ae3'}}>Dashboard</MenuLink></MenuItem>
          <MenuItem><MenuLink href="/investor/wallet">Wallet</MenuLink></MenuItem>
        </Menu>
      </Sidebar>
      <MainContent>
        <Header>
          <Title>Investor Dashboard</Title>
          <UserInfo>
            {/* @ts-ignore */}
            <UserName>{user.username}</UserName>
            <Button onClick={handleLogout}>Logout</Button>
          </UserInfo>
        </Header>
        <CardContainer>
          <Card>
            <h3>Total PST Owned(NO API PROVIDED)</h3>
            <Statistic>5,000 PST</Statistic>
          </Card>
          <Card>
            <h3>Total Dividends Earned(NO API PROVIDED)</h3>
            <Statistic>HK$2,500</Statistic>
          </Card>
          <SmallCard>
            <h3>Current APY(NO API PROVIDED)</h3>
            <Statistic>14.5%</Statistic>
          </SmallCard>
          <SmallCard>
            <h3>Last Dividend(NO API PROVIDED)</h3>
            <Statistic>$150</Statistic>
          </SmallCard>
        </CardContainer>
        <Card style={{ marginTop: '30px' }}>
          <h3>PST Performance(NO API PROVIDED)</h3>
          <ChartPlaceholder>[Chart Placeholder]</ChartPlaceholder>
        </Card>
        <Card style={{ marginTop: '30px' }}>
          <h3>Dividend History(NO API PROVIDED)</h3>
          <DividendTable>
            <thead>
              <tr>
                <Th>Date</Th>
                <Th>Amount</Th>
                <Th>APY</Th>
              </tr>
            </thead>
            <tbody>
              {dummyDividendHistory.map((dividend, index) => (
                <tr key={index}>
                  <Td>{dividend.date}</Td>
                  <Td>{dividend.amount}</Td>
                  <Td>{dividend.apy}</Td>
                </tr>
              ))}
            </tbody>
          </DividendTable>
        </Card>
      </MainContent>
    </DashboardContainer>
  );
}

export default InvestorDashboard;