import React, { useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import api from '../common/api';
import utils from '../common/utils';
import { message } from 'antd';
const LoginContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(135deg, #0f0c29, #302b63, #24243e);
`;

const LoginCard = styled.div`
  background: linear-gradient(45deg, #16213e, #1e2a4a);
  padding: 40px;
  border-radius: 10px;
  width: 300px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
`;

const Logo = styled.h2`
  font-size: 28px;
  font-weight: bold;
  color: #4a7ae3;
  text-align: center;
  margin-bottom: 40px;
  text-shadow: 0 0 10px rgba(74, 122, 227, 0.5);
`;

const LoginButton = styled.button`
  width: 100%;
  margin-top: 20px;
  background: linear-gradient(45deg, #4a7ae3, #6a5acd);
  color: white;
  border: none;
  padding: 12px 24px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: all 0.3s;

  &:hover {
    background: linear-gradient(45deg, #3a62b3, #5a4aad);
    box-shadow: 0 0 10px rgba(74, 122, 227, 0.5);
  }
`;

const Input = styled.input`
  width: 100%;
  padding: 12px 24px;
  border-radius: 5px;
  margin-top: 20px;
  border: none;
  box-sizing: border-box;
  border: 1px solid #4a7ae3;
  background-color: #1a1a2e;
  color: #e0e0e0;
`;

function Login() {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleLogin = (e) => {


    if (e === 'sign up') {
      navigate('/signup');
      return;
    }
    let _email = email;
    let _password = password;
    if (e === 'merchant') {
      _email = "merchant1@example.com";
      _password = "merchant1";
    } else if (e === "investor") {
      _email = "client-4534@email.com";
      _password = "VerySecurePassword111"
    } else if (e === "qupital") {
      _email = "qupital@example.com";
      _password = "qupitalVerySecurePassword111";
    }


    api.post("/sign-in",
      { "email": _email, "password": _password, loadingText: 'Please wait..' }
    ).then((res) => {
      let token = res.data.accessToken;
      // write token to local storage
      localStorage.setItem('token', token);
      let user = res.data.user;
      let role = "investor";
      if (user.isAdmin) {
        role = "qupital";
      } else if (user.isMerchant) {
        role = "merchant";
      };
      message.success('Login successfull');
      user.role = role;
      utils.setValue('user', user);
      navigate('/' + role);
    })
  };

  return (
    <LoginContainer>
      <LoginCard>
        <Logo>QuVault</Logo>
        <Input value={email} name="username" onChange={e => { setEmail(e.target.value) }} placeholder='Email'></Input>
        <Input value={password} name="password" type="password" onChange={e => { setPassword(e.target.value) }} placeholder='Password'></Input>
        <LoginButton onClick={() => handleLogin("")}>Login</LoginButton>
        <LoginButton onClick={() => handleLogin('sign up')}>Sign Up</LoginButton>
        <LoginButton onClick={() => handleLogin('merchant')}>Merchant Login</LoginButton>
        <LoginButton onClick={() => handleLogin('investor')}>Investor Login</LoginButton>
        <LoginButton onClick={() => handleLogin('qupital')}>Qupital Admin Login</LoginButton>
      </LoginCard>
    </LoginContainer>
  );
}

export default Login;