import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './pages/Login';
import MerchantDashboard from './pages/MerchantDashboard';
import InvestorDashboard from './pages/InvestorDashboard';
import QupitalDashboard from './pages/QupitalDashboard';
import GlobalStyles from './GlobalStyles';
import MerchantWallet from './pages/MerchantWallet';
import InvestorWallet from './pages/InvestorWallet';
import DividendDistribution from './pages/DividendDistribution';
import SignUp from './pages/SignUp';

const App = () => {
  return (
    <div className='min-w-[1400px]'>
      <Router>
        <GlobalStyles />
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/merchant" element={<MerchantDashboard />} />
          <Route path="/investor" element={<InvestorDashboard />} />
          <Route path="/qupital" element={<QupitalDashboard />} />
          <Route path="/merchant/wallet" element={<MerchantWallet />} />
          <Route path="/investor/wallet" element={<InvestorWallet />} />
          <Route path="/qupital/dividend" element={<DividendDistribution />} />
          <Route path="/signup" element={<SignUp />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;