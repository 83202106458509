import {
  Button,
} from '../../DashboardStyles';

import { useState } from 'react';
import styled from 'styled-components';
import { message } from 'antd';
import api from '../../common/api';

const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContent = styled.div`
  background: linear-gradient(45deg, #16213e, #1e2a4a);
  padding: 30px;
  border-radius: 10px;
  width: 500px;
`;

const ModalTitle = styled.h2`
  color: white;
  margin-bottom: 20px;
`;

const ActionButton = styled(Button)`
  margin: 5px;
  padding: 8px 16px;
  font-size: 14px;
  width: 100%;
`;

const Input = styled.input`
  width: 75%;
  padding: 10px;
  border-radius: 5px;
  box-sizing: border-box;
  border: 1px solid #4a7ae3;
  background-color: #1a1a2e;
  color: #e0e0e0;
`;

const LabelContainer = styled.div`
margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
export default function SubmitCredentialsModal({ onClose, store, onSubmited }) {

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const handleSubmit = () => {
    let params = {
      store_id: store.id,
      credentials:
      {
        email: email,
        password: password
      }
    }
    api.post('/submit-credentials', params);
    message.success('Credentials submitted successfully');
    onSubmited?.();
    handleClose();
  };
  const handleClose = () => { onClose?.(); };
  return (
    <ModalBackground onClick={handleClose}>
      <ModalContent onClick={(e) => e.stopPropagation()}>
        <ModalTitle>Submit Credentials</ModalTitle>
        <LabelContainer>
          <label style={{ color: '#fff' }} htmlFor="Email">Email:</label>
          <Input value={email} onChange={(e) => { setEmail(e.target.value); }} type="email" placeholder="" required />
        </LabelContainer>

        <LabelContainer>
          <label style={{ color: '#fff' }} htmlFor="password">Password:</label>
          <Input value={password} onChange={(e) => { setPassword(e.target.value) }} type="text" placeholder="" required />
        </LabelContainer>
        <ActionButton onClick={() => handleSubmit()}>
          Submit
        </ActionButton>
      </ModalContent>
    </ModalBackground>
  );
};