import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import {
    Card,
    Th,
    Td,
} from '../../DashboardStyles';
import api from '../../common/api';

const Transaction = forwardRef(({}, ref) => {
    const [recentTransactions, setRecentTransactions] = useState([]);

    const fetchRecentTransactions = async () => {
        try {
            let res = await api.get("/get-recent-transfers");
            setRecentTransactions(res.data);
        } catch (e) {
            console.error(e);
        }
    }

    useImperativeHandle(ref, () => ({
        fetchRecentTransactions
    }));

    useEffect(() => {
        fetchRecentTransactions();
    }, []);

    return (
        <Card style={{ marginTop: '30px' }}>
            <h3>Transaction History</h3>
            <div className='mt-[20px]'>
                <thead>
                    <tr>
                        <Th>Create At</Th>
                        <Th>Amount</Th>
                        <Th>Type</Th>
                        <Th>Currency Symbol</Th>
                        <Th>Chain Symbol</Th>
                        <Th>Recipient Wallet ID</Th>
                        <Th>Sender Wallet Id</Th>
                    </tr>
                </thead>
                <tbody>
                    {recentTransactions.map((transaction, index) => (
                        <tr key={index}>
                            <Td>{transaction.createdAt}</Td>
                            <Td>{transaction.amount}</Td>
                            <Td>{transaction.type}</Td>
                            <Td>{transaction.currencySymbol}</Td>
                            <Td>{transaction.chainSymbol}</Td>
                            <Td>{transaction.recipientWalletId}</Td>
                            <Td>{transaction.senderWalletId}</Td>
                        </tr>
                    ))}
                </tbody>
            </div>
        </Card>
    );
});

export default Transaction;
